.no-event {
  background-color: blue;
}

@media print {
  html {
    width : 100%;
    height: 100%;
  }

  body {
    width : 100%;
    height: 100%;
  }

  #root {
    width : 100%;
    height: 100%;
  }

  .not-printed {
    display: none !important;
  }
}